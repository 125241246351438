import React from 'react';
import styles from './Datenschutz.module.css';

const Datenschutz = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Datenschutzerklärung</h2>
      
      <h3>1. Verantwortlicher</h3>
      <p>
        Verantwortlich für die Verarbeitung personenbezogener Daten im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:<br /><br />
        Max Zipfel<br />
        Kreuzerweg 54, 81825 München<br />
        E-Mail: ciao@ciaobarista.com
      </p>
      
      <h3>2. Erhobene Daten</h3>
      <h4>App:</h4>
      <p>
        - Personenbezogene Daten: E-Mail-Adresse und optional Ihr Name.<br />
        - Zubereitungsparameter: Informationen wie Bohnenart, Mahlgrad und Wassermenge.
      </p>
      <h4>Webseite:</h4>
      <p>
        {/* - Personenbezogene Daten: Name, E-Mail-Adresse, Telefonnummer.<br /> */}
        - Nutzungsdaten: IP-Adresse, Browsertyp, Zugriffszeiten und besuchte Seiten.
      </p>
      
      <h3>3. Zweck der Datenerhebung</h3>
      <h4>App:</h4>
      <p>
        - Verwaltung Ihres Nutzerkontos.<br />
        - Speicherung Ihrer Zubereitungshistorie.<br />
        - Empfehlungen zur Optimierung Ihrer Zubereitung.<br />
        - Weiterentwicklung und Verbesserung der App.
      </p>
      <h4>Webseite:</h4>
      <p>
        - Bereitstellung und Optimierung der Webseite.<br />
        {/* - Beantwortung von Anfragen über das Kontaktformular.<br /> */}
        - Analyse des Nutzerverhaltens zur Verbesserung der Webseite.
      </p>
      
      <h3>4. Speicherdauer</h3>
      <h4>App:</h4>
      <p>
        - Personenbezogene Daten: Bis zur Löschung Ihres Nutzerkontos.<br />
        - Zubereitungsparameter: Anonymisiert oder pseudonymisiert auf unbestimmte Zeit.
      </p>
      <h4>Webseite:</h4>
      <p>
        - Personenbezogene Daten: Bis zur Erfüllung des Zwecks der Datenerhebung oder gemäß gesetzlicher Aufbewahrungsfristen.<br />
        - Nutzungsdaten: In der Regel 6 Monate.
      </p>
      
      <h3>5. Weitergabe von Daten</h3>
      <h4>App:</h4>
      <p>
        - Personenbezogene Daten: Keine Weitergabe an Dritte, es sei denn, dies ist gesetzlich vorgeschrieben oder zur Vertragserfüllung notwendig.<br />
        - Anonymisierte Daten: Können an Geschäftspartner für statistische oder kommerzielle Zwecke weitergegeben werden.
      </p>
      <h4>Webseite:</h4>
      <p>
        - Personenbezogene Daten: Keine Weitergabe an Dritte ohne Ihre ausdrückliche Einwilligung, außer gesetzlich vorgeschrieben.<br />
        - Nutzungsdaten: Können anonymisiert für Analysezwecke weitergegeben werden.
      </p>
      
      <h3>6. Datensicherheit</h3>
      <p>
        Wir setzen geeignete technische und organisatorische Maßnahmen ein, um Ihre Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen.
      </p>
      
      <h3>7. Ihre Rechte</h3>
      <p>
        Sie haben gemäß DSGVO folgende Rechte:<br />
        - Auskunft: Über die über Sie gespeicherten Daten.<br />
        - Berichtigung: Falscher oder unvollständiger Daten.<br />
        - Löschung: Ihrer Daten, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.<br />
        - Einschränkung der Verarbeitung: Unter bestimmten Voraussetzungen.<br />
        - Widerruf: Einer erteilten Einwilligung zur Verarbeitung.<br />
        - Beschwerde: Bei der zuständigen Datenschutzaufsichtsbehörde.
      </p>
      
      <h3>8. Änderungen der Datenschutzbestimmungen</h3>
      <p>
        Wir behalten uns vor, diese Datenschutzbestimmungen bei Änderungen in der Gesetzgebung oder der App/Webseite zu aktualisieren. Änderungen werden in der App und auf der Webseite veröffentlicht.
      </p>
      
      <h3>9. Kontakt</h3>
      <p>
        Für Fragen oder Anliegen zum Datenschutz kontaktieren Sie uns bitte unter:<br />
        ciao@ciaobarista.com
      </p>
    </div>
  );
};

export default Datenschutz;